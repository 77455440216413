import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { GlobalStateProvider } from './components/GlobalStateContext';
import useQueryParamsManager from './components/QueryParamManager';
import { CardStateProvider } from './components/CardStateContext';
import BackToTop from './components/common/BackToTop';

const Home = React.lazy(() => import('./pages/Home'));
const Heroes = React.lazy(() => import('./pages/Heroes'));
const Cards = React.lazy(() => import('./pages/Cards'));
const NavMenu = React.lazy(() => import('./components/NavMenu'));
const Quests = React.lazy(() => import('./pages/Quests'));
const Viewer = React.lazy(() => import('./pages/Viewer'));
const Missing = React.lazy(() => import('./pages/Missing'));
const Faq = React.lazy(() => import('./pages/Faq'));

const Providers: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { getQueryParam, getIdsFromQueryParam, modifyListInQueryParams, updateQueryParams } = useQueryParamsManager();

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <GlobalStateProvider>
        <CardStateProvider
          getQueryParam={getQueryParam}
          getIdsFromQueryParam={getIdsFromQueryParam}
          modifyListInQueryParams={modifyListInQueryParams}
          updateQueryParams={updateQueryParams}
        >
          {children}
        </CardStateProvider>
      </GlobalStateProvider>
    </Suspense>
  );
};

function App() {
  const { updateQueryParams, getQueryParam } = useQueryParamsManager();

  return (
    <Providers>
      <div
        style={{ 'height': '50px' }}
      >
        <Suspense fallback={<div>Loading menu...</div>}>
          <NavMenu/>
        </Suspense>
      </div>
      <div className="flex">
        <div className={`flex-1 relative`}>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/viewer/:id?/:index?"
                   element={
                     <Suspense fallback={<div>Loading...</div>}>
                       <Viewer
                         getQueryParam={getQueryParam}
                         updateQueryParams={updateQueryParams}
                       />
                     </Suspense>
                   }
            />
            <Route path="/heroes"
                   element={
                     <Suspense fallback={<div>Loading...</div>}>
                       <Heroes
                         updateQueryParams={updateQueryParams}
                       />
                     </Suspense>
                   }
            />
            <Route path="/cards"
                   element={
                     <Suspense fallback={<div>Loading...</div>}>
                       <Cards
                         getQueryParam={getQueryParam}
                         updateQueryParams={updateQueryParams}
                       />
                     </Suspense>
                   }
            />
            <Route path="/quests"
                   element={
                     <Suspense fallback={<div>Loading...</div>}>
                       <Quests
                         updateQueryParams={updateQueryParams}
                       />
                     </Suspense>
                   }
            />
            <Route path="/faq"
                   element={
                     <Suspense fallback={<div>Loading...</div>}>
                       <Faq/>
                     </Suspense>
                   }
            />
            <Route path="/missing"
                   element={
                     <Suspense fallback={<div>Loading...</div>}>
                       <Missing/>
                     </Suspense>
                   }
            />
          </Routes>
        </div>
        <BackToTop/>
      </div>
    </Providers>
  );
}

export default App;
